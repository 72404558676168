<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="isMobile"
    :width="getWidth"
    v-bind="$attrs"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator"></slot>
    </template>

    <v-toolbar color="primary-lighten-2" :elevation="8" rounded>
      <v-toolbar-title>
        <slot name="title"></slot>
      </v-toolbar-title>
      <v-btn
        v-if="showClose"
        icon="mdi-close"
        variant="plain"
        size="small"
        @click="showDialog = false"
      />
      <template v-if="$slots['subheader']" #extension>
        <slot name="subheader"></slot>
      </template>
    </v-toolbar>

    <!-- Dialog Body -->
    <v-card variant="flat">
      <v-card-text class="leave-room">
        <info-block v-if="$slots['info']">
          <slot name="info"></slot>
        </info-block>

        <slot></slot>
      </v-card-text>

      <v-card-actions :class="getActionsJustifcation">
        <!-- If we show-close then display a close button. The style and position depend on how mnay other actions there are -->
        <v-btn
          v-if="showClose"
          color="primary"
          :variant="hasActions > 0 && !isDarkMode ? 'text' : 'flat'"
          :block="isMobile && hasActions === 0"
          @click="showDialog = false"
        >
          {{ $t("common.core.close") }}
        </v-btn>
        <div>
          <slot name="actions">&nbsp;</slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SimpleDialog",
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "85%",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    showDialog: false,
  }),
  computed: {
    hasActionsSlot() {
      return !!this.$slots["actions"];
    },
    getWidth() {
      return this.$vuetify.display.smAndDown ? "100%" : this.width;
    },
    hasActions() {
      return this.hasActionsSlot ? this.filteredActions?.length > 0 : false;
    },
    isDarkMode() {
      return this.$vuetify.theme.current.dark;
    },
    filteredActions() {
      const processSlots = (slots) => {
        return slots.reduce((actions, slot) => {
          // Check if the slot exists and has children, indicating it's a fragment
          if (slot && Array.isArray(slot.children)) {
            // Assuming all objects with children are fragment-like containers
            actions.push(...processSlots(slot.children));
          } else if (
            // Elements with conditions like v-if still being evaluated in the slot
            // and comes with undefined element and also filter out any comments
            slot !== undefined &&
            !(slot.type && typeof slot.type === "symbol")
          ) {
            // If the slot is a regular node add it to actions
            actions.push(slot);
          }
          return actions;
        }, []);
      };

      return processSlots(this.$slots.actions());
    },
    getActionsJustifcation() {
      // If showClose + action = justify-space-between
      // If !showClose + action = justify-end
      // If !showClose + !action = justify-end
      return this.showClose && this.hasActions
        ? "justify-space-between"
        : "justify-end";
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  watch: {
    // Handle v-model on parent to trigger dialog
    modelValue(to) {
      this.showDialog = to;

      if (to === false) {
        // Stop the activator remaining in a pressed state when closed.
        this.$nextTick(() => {
          document.activeElement.blur();
        });
      }
    },
    // Handle passing back to parent when dialog closes
    showDialog(to) {
      this.$emit("update:modelValue", to);
    },
  },
  mounted() {
    if (this.modelValue === true) {
      this.showDialog = this.modelValue;
    }
  },
};
</script>

<style scoped>
/**
 * Make sure that the dialog doesn't go over the screen height
 * Especially useful for short mobile devices so buttons always appear
 * and middle content is scrollable
 */
.leave-room {
  max-height: 80dvh;
  overflow-y: auto;
}
</style>

import { RE_MATTER_CODE } from "@/config/constants.js";
import Cache from "@/services/cacheService.js";

const CACHE_NAME = "matters-groups";
const BASE_URL = "/v1/matters/groups";
const TAG_BASE_URL = "/v1/groups/groups";

export default class MatterGroupsAPI {
  #baseURL = `${import.meta.env.VITE_HSF_API_BASE_URL}/expertsync`;
  constructor(http) {
    this.http = http;
  }

  /**
   * Clear the cache for a specific group
   * @param {String} id The groupId to clear from the cache
   * @returns Promise When all items have been cleared
   */
  #clearGroup(id) {
    return Promise.all(
      [BASE_URL, `${BASE_URL}/${id}`].map(async (url) => {
        await Cache.clear(CACHE_NAME, url, {
          ignoreSearch: true,
        });
      })
    );
  }

  /**
   * Get all the matter groups
   * @param {Boolean} expandDetail Show expanded item data in results
   * @param {Boolean} startsWith Filter results for starting with
   * @param {Object} pagination An object with an offset and limit
   * @param {Object} sortBy An object with a column and order
   * @param {String} genesisType Genesis type to filter data on genesis ex. arbitration/litigation
   * @returns Promise
   */
  async getGroups(
    expandDetail = false,
    startsWith = "",
    pagination = { offset: 0, limit: 10 },
    sortBy = { column: "created", order: "desc" },
    genesisType = ""
  ) {
    let params = {
      info: expandDetail,
      name: startsWith,
      genesistype: genesisType,
    };

    // Handle the getting all the data at once (not recommended)
    if (pagination && pagination.limit !== -1) {
      params = Object.assign(params, pagination);
    }

    if (sortBy) {
      const orderBy = `${sortBy.column} ${sortBy.order}`;
      params = Object.assign(params, { order: orderBy });
    }

    return this.http.get(BASE_URL, { params: params });
  }

  /**
   * Get all the tags for matter groups
   * @param {Boolean} expandDetail Show expanded item data in results
   * @param {String} startsWith Filter results for starting with
   * @param {Object} pagination Gets number of results based on limit defined in pagination
   * @returns Promise
   */
  async getTags(
    expandDetail = false,
    startsWith = "",
    pagination = { offset: 0, limit: 10 }
  ) {
    let params = {
      info: expandDetail,
      name: startsWith,
    };

    // Handle the getting all the data at once (not recommended)
    if (pagination && pagination.limit !== -1) {
      params = Object.assign(params, pagination);
    }

    return this.http.get(TAG_BASE_URL, { params: params });
  }

  /**
   * Get a single group by group id
   * @param {*} groupId The group id
   * @param {*} expandDetail Show extended details about it's members
   * @returns Promise
   */
  async getGroup(groupId, expandDetail = false) {
    if (!groupId || typeof groupId !== "string") {
      throw new Exception("Missing group id to fetch details for.");
    }

    return this.http.get(`${BASE_URL}/${groupId}`, {
      params: { info: expandDetail },
    });
  }

  /**
   * Create a new Matter Group with a specified name.
   * @param {String} name The name of the group being created
   * @returns Promise
   */
  async createGroup(name) {
    const groupName = name.trim();
    if (!groupName) {
      throw new Exception("No name has been supplied to create a group.");
    }

    const data = {
      data: {
        groups: [
          {
            groupName: groupName,
          },
        ],
      },
    };

    const response = await this.http.post(BASE_URL, data);
    await Cache.clear(CACHE_NAME, "/v1/matters/groups", {
      ignoreSearch: true,
    });

    return response;
  }

  /**
   * Either creating new tags and adding members or removing tags
   * @param {Array} tags The array of objects of tags being created
   * @param {Number} groupId The id of the matter group
   * @returns Promise
   */
  async updateTags(tags, groupId) {
    if (!groupId) {
      throw new Error("Missing Group ID to assign tags to");
    }

    const data = {
      data: {
        groups: tags,
      },
    };
    return this.http.put(`/v1/groups/${groupId}/groups`, data);
  }

  /**
   * Remove a matter group
   * @param {Number} id The id of the matter group
   * @returns Promise
   */
  async deleteGroup(id) {
    if (!id || typeof id !== "string") {
      throw new Exception("Cannot delete invalid group id.");
    }

    const response = await this.http.delete(`${BASE_URL}/${id}`);
    await this.#clearGroup(id);

    return response;
  }

  /**
   * Edit the name of the group
   * @param {String} id The UUID of the group
   * @param {String} name The new name
   * @returns Promise
   */
  async editGroupName(id, name) {
    const data = {
      data: {
        group: {
          groupName: name,
        },
      },
    };

    const response = await this.http.patch(`${BASE_URL}/${id}`, data);
    await this.#clearGroup(id);

    return response;
  }

  /**
   * Add a matter to a matter group
   * @param {Number} id The id of the matter group
   * @param {Array} matterCodeData The array of code of the matter
   * @returns Promise
   */
  async addMatterToGroup(groupId, matterCodeData = []) {
    if (!groupId || typeof groupId !== "string") {
      throw new Exception("Missing group id to add matter to");
    }
    const memberApiData = [];
    matterCodeData.forEach((matterCode) => {
      if (!RE_MATTER_CODE.test(matterCode)) {
        throw new Exception("Invalid matter code when adding to group");
      }
      const instanceData = { instance: matterCode };
      memberApiData.push(instanceData);
    });
    const data = {
      data: {
        members: memberApiData,
      },
    };

    const response = await this.http.post(
      `${BASE_URL}/${groupId}/members`,
      data
    );
    await this.#clearGroup(groupId);

    return response;
  }

  /**
   * Remove a matter from a matter group
   * @param {Number} id The id of the matter group
   * @param {String} matterId The UUID of the element in the group to remove
   * @returns Promise
   */
  async removeMatterFromGroup(id, matterId) {
    if (!id || typeof id !== "string") {
      throw new Exception("Missing group id to remove matter from");
    }

    const response = await this.http.delete(
      `${BASE_URL}/${id}/members/${matterId}`
    );
    await this.#clearGroup(id);

    return response;
  }

  /**
   * Set matter as lead matter in a group
   * @param {Number} matterInstanceId The instance id of matter for lead matter
   * @param {Number} groupId The id of the matter group
   * @returns Promise
   */
  async setMatterAsLead(matterInstanceId, groupId) {
    if (!matterInstanceId) {
      throw new Error("Missing `matterInstanceId` to add to lead matter");
    }
    if (!groupId) {
      throw new Error("Missing `groupId` to add lead matter");
    }
    return this.http.put(`/v1/matters/groups/${groupId}/members?info=true`, {
      instance: matterInstanceId,
      isleadmatter: true,
    });
  }

  /**
   * Sync the lead matter data to arbitration
   * @param {Object} data The object for sync in lead matter
   * @returns Promise
   */
  async arbitrationMatterSync(data) {
    if (!data) {
      throw new Error(`Missing data. Expected {
        "containerId": "containerId",
        "matters": [
            {
                "matterCode" : "matterCode",
                "isLeadMatter" : boolean
            }            
        ]
      } got ${data.toString}`);
    }

    return this.http.post(`${this.#baseURL}/v1/arbitration/container`, data);
  }

  /**
   * Sync the lead matter data to litigation
   * @param {Object} data The object for sync in lead matter
   * @returns Promise
   */
  async litigationMatterSync(data) {
    if (!data) {
      throw new Error(`Missing data. Expected {
        "containerId": "containerId",
        "matters": [
            {
                "matterCode" : "matterCode",
                "isLeadMatter" : boolean
            }            
        ]
      } got ${data.toString}`);
    }
    return this.http.post(`${this.#baseURL}/v1/litigation/container`, data);
  }
}

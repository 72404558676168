<template>
  <template v-if="error || hasCustomError">
    <!-- v-snackbar style error -->
    <template v-if="snackbar">
      <v-snackbar
        v-model="showSnackbar"
        location="bottom"
        color="error"
        :timeout="getSnackbarTimeout"
        :timer="getSnackbarTimeout === -1 ? false : 'error-lighten-2'"
        multi-line
        @update:model-value="onCloseSnackbar"
      >
        <template v-if="!showDetails">
          <div class="font-weight-bold">{{ getErrorMessage.title }}</div>
          <div v-if="getErrorMessage.description" class="text-caption">
            {{ getErrorMessage.description }}
          </div>
        </template>
        <template v-else>
          <div v-if="error.name" class="font-weight-medium">
            {{ error?.name }}
          </div>
          <div class="font-weight-regular">{{ getRawMessage }}</div>
        </template>
        <div class="text-caption font-weight-light" data-chromatic="ignore">
          {{ timeStamp }}
        </div>
        <template #actions>
          <v-btn v-if="!showDetails" @click="showDetails = !showDetails">
            {{ $t("common.core.more") }}
          </v-btn>
          <v-btn v-if="retry" block variant="text" @click="emitRetry">
            {{ $t("common.core.retry") }}
          </v-btn>
        </template>
      </v-snackbar>
    </template>

    <!-- v-alert style error -->
    <template v-else>
      <!-- Wrap v-alert in a v-sheet to contain error button actions -->
      <v-sheet width="100%" color="error" class="pa-1 rounded-t rounded-b">
        <v-alert color="error" icon="mdi-alert">
          <template #title>
            <div style="width: 100%" class="d-flex justify-space-between">
              <div>
                {{ getErrorMessage.title }}
              </div>
              <div
                v-if="!isMobile"
                class="text-caption"
                data-chromatic="ignore"
              >
                {{ timeStamp }}
              </div>
            </div>
          </template>
          <template #text>
            <div class="text-left">
              <h4 class="mt-2 mb-2">
                {{ $t("matters.groups.matter-group-error.next") }}
              </h4>
              <ul class="ml-5">
                <li v-if="getErrorMessage.description">
                  {{ getErrorMessage.description }}
                </li>
                <li v-if="getErrorMessage.serviceDesk">
                  {{ getErrorMessage.serviceDesk }}
                </li>
              </ul>
            </div>
          </template>
        </v-alert>
        <v-row justify="end" class="ma-1">
          <v-col
            v-if="isMobile"
            cols="12"
            md="6"
            class="pa-0 mb-1 text-right text-caption"
          >
            {{ timeStamp }}
          </v-col>
          <v-col cols="12" md="6" class="pa-0 text-right">
            <v-btn
              v-if="retry"
              class="mr-2"
              variant="flat"
              color="error-darken-1"
              :size="isMobile ? 'small' : undefined"
              @click="emitRetry"
            >
              {{ $t("common.core.retry") }}
            </v-btn>
            <v-btn
              v-if="createIncidentURL && canParseURL"
              append-icon="mdi-open-in-new"
              variant="flat"
              color="error-darken-1"
              :size="isMobile ? 'small' : undefined"
              :href="createIncidentURL"
              target="_blank"
            >
              {{ $t("error-messages.sd-title") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
  </template>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import axios from "axios";
import errorStrategies from "./ErrorStrategies.js";
dayjs.extend(utc);

export default {
  name: "ErrorMessage",
  props: {
    error: {
      type: [String, Error, Object],
      default: undefined,
    },
    retry: {
      type: Boolean,
      default: false,
    },
    snackbar: {
      type: [Boolean, Number],
      default: false,
    },
    customTitle: {
      type: String,
      default: null,
    },
    customDescription: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
  },
  emits: ["retry", "closed"],
  data() {
    return {
      timeStamp: null,
      createIncidentURL: import.meta.env.VITE_CREATE_INCIDENT_URL,
      showSnackbar: false,
      showDetails: false,
    };
  },
  computed: {
    canParseURL() {
      const url = window.URL || window.webkitURL;

      return url.canParse(this.createIncidentURL);
    },
    hasCustomError() {
      return this.customTitle || this.customDescription;
    },
    getSnackbarTimeout() {
      return typeof this.snackbar === "number" ? this.snackbar : 5000;
    },
    getErrorMessage() {
      const strategies = [
        {
          // checks if its a custom error handle.
          condition: () => this.hasCustomError,
          // Use formatted custom error message.
          action: () =>
            errorStrategies.custom(
              this.customTitle,
              this.customDescription ?? this.error // Fallback to standard error if no description is provided
            ),
        },
        {
          // Check if the error is Storybook error or instance of axios error
          condition: () =>
            this.error.isAxiosError || this.error instanceof axios.AxiosError,
          action: () => {
            // In production, log the error details to New Relic, including source and user email.
            if (
              import.meta.env.PROD &&
              !import.meta.env.VITE_STORYBOOK_DEV_MODE
            ) {
              // Only in production mode and not in Storybook dev mode
              this.$newRelic.noticeError(this.error, {
                source: this.source,
                user: this.$auth.emailAddress(),
              });
            }
            // eslint-disable-next-line no-console
            console.warn(`${this.source} AXIOS ERROR:`, this.error);
            // Return the error as formatted axios message
            return errorStrategies.axiosError(this.error);
          },
        },
        {
          // checks if the error is a string.
          condition: () => typeof this.error === "string",
          // return the error as a simple string message.
          action: () => errorStrategies.stringError(this.error),
        },
        {
          // Fallback condition: always true, acts as a default case.
          condition: () => true,
          // Return a generic error message.
          action: () => errorStrategies.fallback(),
        },
      ];

      // Evaluate each strategy in order and select the first one where the condition is true.
      // Execute the corresponding action to get the error message object.
      const message =
        strategies.find(({ condition }) => condition())?.action() ?? {};

      // If this error is meant to be displayed in a snackbar and a source is provided,
      // modify the error title to include the source information.
      if (this.snackbar && this.source) {
        message.title = `[${this.source}] ${message.title}`;
      }

      // Return the final error message object.
      return message;
    },
    /** Gets the raw message from the error object rather than the "friendly" one */
    getRawMessage() {
      if (typeof this.error === "string") {
        return this.error;
      }

      return this.error?.message ?? "";
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  watch: {
    // Handle snackbar visiblity. Needs v-model so timeout can set it back to false automatically
    error(to, from) {
      if (to !== from && to !== undefined) {
        this.showSnackbar = true;
      }
    },
  },
  mounted() {
    this.timeStamp = dayjs().utc().format("YYYY-MM-DD HH:mm:ssUTC");
  },
  methods: {
    emitRetry() {
      this.$emit("retry");
    },
    onCloseSnackbar() {
      this.$emit("closed", this.showSnackbar);
    },
  },
};
</script>

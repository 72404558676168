import { Agent as BrowserAgent } from "@newrelic/browser-agent/loaders/agent";
import { Ajax } from "@newrelic/browser-agent/features/ajax";
import { JSErrors } from "@newrelic/browser-agent/features/jserrors";
import { Metrics } from "@newrelic/browser-agent/features/metrics";
import { PageAction } from "@newrelic/browser-agent/features/page_action";
import { PageViewEvent } from "@newrelic/browser-agent/features/page_view_event";
import { PageViewTiming } from "@newrelic/browser-agent/features/page_view_timing";
import { SessionTrace } from "@newrelic/browser-agent/features/session_trace";
import { Spa } from "@newrelic/browser-agent/features/spa";
// @see https://github.com/newrelic/newrelic-browser-agent for configuration options

// Populated using values in copy-paste JavaScript snippet.
// @see Tracking the ability to pull this from Terraform in https://github.com/newrelic/terraform-provider-newrelic/issues/2388
const options = import.meta.env.VITE_ENABLE_NEWRELIC
  ? {
      init: {
        ajax: {
          deny_list: [import.meta.env.VITE_NEWRELIC_BEACON],
        },
        distributed_tracing: {
          enabled: import.meta.env.VITE_NEWRELIC_DISTRIBUTED_TRACING,
        },
        privacy: {
          cookies_enabled: import.meta.env.VITE_NEWRELIC_COOKIES_ENABLED,
        },
      },
      info: {
        applicationID: import.meta.env.VITE_NEWRELIC_APPLICATIONID,
        beacon: import.meta.env.VITE_NEWRELIC_BEACON,
        errorBeacon: import.meta.env.VITE_NEWRELIC_BEACON,
        licenseKey: import.meta.env.VITE_NEWRELIC_LICENSEKEY,
        sa: 1,
      },
      loader_config: {
        accountID: import.meta.env.VITE_NEWRELIC_ACCOUNTID,
        agentID: import.meta.env.VITE_NEWRELIC_APPLICATIONID,
        applicationID: import.meta.env.VITE_NEWRELIC_APPLICATIONID,
        licenseKey: import.meta.env.VITE_NEWRELIC_LICENSEKEY,
        trustKey: import.meta.env.VITE_NEWRELIC_ACCOUNTID,
      },
    }
  : {};

// The agent loader code executes immediately on instantiation.
export default new BrowserAgent({
  ...options,
  features: [
    Ajax,
    JSErrors,
    Metrics,
    PageAction,
    PageViewEvent,
    PageViewTiming,
    SessionTrace,
    Spa,
  ],
});

export const engineStrategies = {
  pbn: {
    getItemLength: (results) =>
      results.filter((person) => person.data.mail !== null).length > 0,
    getItemResults: (results) =>
      results.filter((person) => person.data.mail !== null),
  },
  default: {
    getItemLength: (results) => results.length > 0,
    getItemResults: (results) => results,
  },
};

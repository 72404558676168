import { LOCALSTORAGEKEY_WEBPUSH } from "@/config/constants.js";

export default {
  data() {
    return {
      hasServiceWorker: false,
      hasNotificationsEnabled: false,
      hasNotificationsBlocked: false,
      hasSubscriptonForThisDevice: false,
      allSubscriptions: [],
      currentSubscription: null,

      notificationError: null,
    };
  },
  computed: {
    isNotificationsEnabled() {
      return this.hasServiceWorker && this.hasNotificationsEnabled;
    },
    hasSubscription() {
      return this.hasSubscriptonForThisDevice || this.hasAnySubscriptions;
    },
    hasAnySubscriptions() {
      return this.allSubscriptions.length > 0;
    },
  },
  async created() {
    if (this.$featureFlag.isVisible("enableNotifications")) {
      // Check to see if Browser Notifications are enabled
      this.checkNotificationPermissions();
      this.checkForCurrentSubscription();
    }
  },
  methods: {
    /**
     * Check whether we can send Notifications to this user
     */
    checkNotificationPermissions() {
      if (!("Notification" in window)) {
        return;
      }

      switch (Notification.permission) {
        case "granted":
          this.setNotificationAPIGranted();
          break;
        case "denied":
          this.setNotificationAPIDenied();
          break;
      }
    },

    /**
     * Set the required flags for the Notification API as being enabled
     */
    setNotificationAPIGranted() {
      this.hasNotificationsEnabled = true;
      this.hasNotificationsBlocked = false;
    },

    /**
     * Set the required flags for the Notification API as being disabled/blocked
     */
    setNotificationAPIDenied() {
      this.hasNotificationsEnabled = false;
      this.hasNotificationsBlocked = true;
    },

    /**
     * Load current subscriptions for user across all devices
     */
    async checkForCurrentSubscription() {
      // Check to see if a Service Worker exists
      const registration = await navigator.serviceWorker.getRegistration();

      this.hasServiceWorker = !!registration?.active;
      // Check to see if there are any current subscriptions
      if (this.hasServiceWorker) {
        const {
          data: {
            data: { subscriptions = [] },
          },
        } = await this.getSubscription();

        this.updateSubscriptions(subscriptions);
      }
    },

    /**
     * Update the subscription list and current subscription.
     * @param {Array} subscriptions The array of subscription objects returned from API
     */
    async updateSubscriptions(subscriptions) {
      // Check to see if a Service Worker exists
      const registration = await navigator.serviceWorker.getRegistration();

      if (registration) {
        this.allSubscriptions = subscriptions;
        const allUserEndpoints =
          subscriptions?.map((sub) => sub.endpoint) ?? [];

        const currentSWSubscription =
          await registration.pushManager.getSubscription();

        if (currentSWSubscription) {
          const userSubsIncludeCurrent = allUserEndpoints.includes(
            currentSWSubscription?.endpoint
          );

          // Check if all the user's subscriptions include this current subscription from SW
          if (userSubsIncludeCurrent) {
            // Have they got notifications enabled on THIS device?
            this.hasNotificationsEnabled = true;
            // Store the current subscription from the service worker
            this.currentSubscription = currentSWSubscription;
            // Is THIS device the current subscription
            this.hasSubscriptonForThisDevice = true;
          }
        }
      }
    },

    /**
     * Add a new subscription and return a list of all active subscriptions.
     * @returns {Array} List of subscriptions if set
     * @throws Exception on failure to load subscriptions
     */
    async activateNotifications() {
      const permission = await Notification.requestPermission();
      if (permission === "denied") {
        this.setNotificationAPIDenied();
        localStorage.setItem(LOCALSTORAGEKEY_WEBPUSH, false);
        return;
      }

      try {
        const {
          data: {
            data: { subscriptions },
          },
        } = await this.addSubscription();

        // Set notification state in localStorage so `/loading` can refresh on login
        localStorage.setItem(LOCALSTORAGEKEY_WEBPUSH, true);

        // Show a "ok, we've turned it on" notification to the user.
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(
            this.$t("common.components.user-settings.notifications-enabled"),
            {
              body: this.$t(
                "common.components.user-settings.notifications-enabled-body"
              ),
              icon: "/img/icons/android-chrome-192x192.png",
            }
          );
        });

        return subscriptions;
      } catch (error) {
        // Set error globally, but could be modified by implementation later
        this.notificationError = error;

        // Rethrow error to be caught in implementation
        throw error;
      }
    },

    /**
     * Turn off Notifications for this user
     * @returns {string} The endpoint URL that was removed
     * @throws Exception on failure to remove notification
     */
    async deactivateNotifications() {
      try {
        localStorage.setItem(LOCALSTORAGEKEY_WEBPUSH, false);

        const {
          data: {
            data: { endpoint = null },
          },
        } = await this.removeSubscription();

        this.hasSubscriptonForThisDevice = false;
        this.currentSubscription = null;

        return endpoint;
      } catch (error) {
        // Set error globally, but could be modified by implementation later
        this.notificationError = error;

        // Rethrow error to be caught in implementation
        throw error;
      }
    },

    /**
     * Convert a base64 string to Uint8Array.
     * Must do this so the server can understand the VAPID_PUBLIC_KEY.
     * @see https://web.dev/push-notifications-server-codelab/
     * @param {String} base64String The base64 string to be encoded into Uint8
     * @return {String}
     */
    urlB64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    /**
     * Ask the user for permission to send push notifications and
     * subscribe to the WebPush service
     */
    async addSubscription() {
      if (!("Notification" in window)) {
        return;
      }

      const vapidPublicKey = import.meta.env.VITE_PUSH_VAPID_KEY;
      const registration = await navigator.serviceWorker.getRegistration();

      // Only continue if we have an active service worker
      if (registration?.active) {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlB64ToUint8Array(vapidPublicKey),
        });

        const isLocal = JSON.parse(import.meta.env.VITE_PUSH_LOCAL_DEV ?? false)
          ? "PushAddSubscription"
          : "";

        return await this.$http.post(
          `${import.meta.env.VITE_PUSH_BASE_URL}/subscription/${isLocal}`,
          subscription,
          {
            "Content-Type": "application/json",
          }
        );
      }
    },

    /**
     * Remove a subscription from the WebPush service
     */
    async removeSubscription() {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        // Only continue if we have an active service worker
        if (registration) {
          return registration.pushManager
            .getSubscription()
            .then(async (subscription) => {
              const unsubscribed = await subscription.unsubscribe();
              if (unsubscribed) {
                const isLocal = JSON.parse(
                  import.meta.env.VITE_PUSH_LOCAL_DEV ?? false
                )
                  ? "PushRemoveSubscription"
                  : "";

                return this.$http.delete(
                  `${
                    import.meta.env.VITE_PUSH_BASE_URL
                  }/subscription/${isLocal}`,
                  {
                    Accepts: "application/json",
                    data: {
                      endpoint: subscription.endpoint, // Only remove this specific subscription
                    },
                  }
                );
              }

              throw new Error(this.$t("common.webpush.unsubscribe-error"));
            });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },

    /**
     * Check if a user has a WebPush subscription enabled
     * @returns {Promise}
     */
    async getSubscription() {
      const isLocal = JSON.parse(import.meta.env.VITE_PUSH_LOCAL_DEV ?? false)
        ? "PushGetSubscription"
        : "";

      return this.$http.get(
        `${import.meta.env.VITE_PUSH_BASE_URL}/subscription/${isLocal}`,
        {
          Accepts: "application/json",
        }
      );
    },
  },
};

import ArbitrationAPI from "@/modules/genesis/_api/arbitrationApi.js";
import LitigationAPI from "@/modules/genesis/_api/litigationApi.js";
import MatterGroupsAPI from "@/modules/matters/_api/matterGroupsApi.js";
import { parseError } from "@/services/httpResponse.js";

const createStore = (app) => {
  const getters = {
    getCurrentTab() {
      return {
        tabIndex: state.currentTab,
        entityId: state.entityId,
        componentName: state.componentName,
      };
    },
  };

  const actions = {
    async loadFieldsAction({ commit }, { genesisId, genesisType }) {
      try {
        commit("setFieldsLoading", {
          type: "isFieldsLoading",
          isFieldsLoading: true,
        });
        commit("setError", {
          type: "loadingError",
          error: "",
        });
        const apiInstance =
          genesisType === "arbitration"
            ? new ArbitrationAPI(app.config.globalProperties.$http)
            : new LitigationAPI(app.config.globalProperties.$http);

        const { data: response } = await apiInstance.getFields(genesisId);
        commit("setFields", response.data.components);
      } catch (err) {
        commit("setError", {
          type: "loadingError",
          error: parseError(err),
        });
      } finally {
        commit("setFieldsLoading", {
          type: "isFieldsLoading",
          isFieldsLoading: false,
        });
      }
    },

    async loadComponents({ commit }) {
      try {
        commit("setComponentListLoading", true);
        const {
          data: { data },
        } = await new ArbitrationAPI(
          app.config.globalProperties.$http
        ).getComponents();
        const components = data;
        commit("setComponentList", components);
      } catch (error) {
        commit("setComponentListError", error);
      } finally {
        commit("setComponentListLoading", false);
      }
    },

    async loadEntityFieldsAction(
      { commit, state },
      { genesisId, entityId, componentName }
    ) {
      try {
        commit("setEntityFieldsLoading", {
          type: "isEntityFieldsLoading",
          isEntityFieldsLoading: true,
        });
        commit("setError", {
          type: "loadingError",
          error: "",
        });
        commit("setEntityFields", []);
        const {
          data: { data },
        } =
          state.genesisType === "arbitration"
            ? await new ArbitrationAPI(
                app.config.globalProperties.$http
              ).getEntityFields(genesisId, entityId, componentName)
            : await new LitigationAPI(
                app.config.globalProperties.$http
              ).getEntityFields(genesisId, entityId, componentName);
        commit("setEntityFields", data.components);
        commit("setEntityData", data);
      } catch (err) {
        commit("setError", {
          type: "loadingError",
          error: parseError(err),
        });
      } finally {
        commit("setEntityFieldsLoading", {
          type: "isEntityFieldsLoading",
          isEntityFieldsLoading: false,
        });
      }
    },

    async setTabsAction(
      { commit, state },
      { currentGenesisFormId, componentName, isComponentAdded, genesisType }
    ) {
      try {
        commit("setTabsLoading", {
          type: "isTabsLoading",
          isTabsLoading: true,
        });
        commit("setError", {
          type: "loadingError",
          error: "",
        });
        commit("setComponentInfoText", "");
        let data;
        if (isComponentAdded) {
          const apiInstance =
            genesisType === "arbitration"
              ? new ArbitrationAPI(app.config.globalProperties.$http)
              : new LitigationAPI(app.config.globalProperties.$http);

          const { data: response } =
            await apiInstance.getGenesisComponents(currentGenesisFormId);
          data = response.data.components;
          commit("setAllTabs", data);
        } else {
          data = state.allTabs;
        }
        const componentIndex = data.findIndex(
          (x) => x.componentName.toString() === componentName.toString()
        );
        if (componentIndex !== -1) {
          const tabs = data[componentIndex].entities;
          const infoText = data[componentIndex].componentHeader;
          commit("setTabs", tabs);
          commit("setComponentInfoText", infoText);
        }
      } catch (error) {
        commit("setError", {
          type: "loadingError",
          error: parseError(err),
        });
      } finally {
        commit("setTabsLoading", {
          type: "isTabsLoading",
          isTabsLoading: false,
        });
      }
    },
    async getGenesisForms(
      { commit, dispatch },
      { currentGroupId, genesisType }
    ) {
      try {
        commit("setGenesisError", {
          type: "loadingGenesisError",
          error: "",
        });
        commit("setGenesisDetailsLoading", {
          type: "isGenesisDetailsLoading",
          isGenesisDetailsLoading: true,
        });
        commit("setGenesisData", []);

        const apiInstance =
          genesisType === "arbitration"
            ? new ArbitrationAPI(app.config.globalProperties.$http)
            : new LitigationAPI(app.config.globalProperties.$http);

        const { data: responseData } =
          await apiInstance.getGenesisData(currentGroupId);
        const genesisData = responseData.data;
        if (genesisData.length > 0) {
          const genesisDetail = genesisData.map((v) => {
            return {
              name: v.arbitrationName,
              id: v.id,
              sectionName: v.sectionName,
            };
          });

          const apiInstance =
            genesisType === "arbitration"
              ? new ArbitrationAPI(app.config.globalProperties.$http)
              : new LitigationAPI(app.config.globalProperties.$http);

          const promiseResult = await apiInstance.getGenesisComponents(
            genesisDetail[0].id
          );

          genesisDetail[0].components = promiseResult.data.data.components;
          const currentGenesisFormId = genesisDetail[0].id;
          await dispatch("loadFieldsAction", {
            genesisId: genesisDetail[0].id,
            genesisType: genesisType,
          });
          commit("setCurrentGenesisFormId", currentGenesisFormId);
          commit("setGenesisData", genesisDetail);
          commit("setAllTabs", genesisDetail[0].components);
          const {
            data: { data: group },
          } = await new MatterGroupsAPI(
            app.config.globalProperties.$http
          ).getGroup(currentGroupId);
          // Add to recently viewed list
          const id = genesisType === "arbitration" ? "abn" : "ltg";
          await dispatch(
            "search/addToRecent",
            {
              id: id + currentGroupId,
              type: "genesis",
              item: {
                title: group.groupName,
                linkId: currentGroupId,
                genesisType: genesisType,
              },
            },
            { root: true }
          );
        }
      } catch (error) {
        commit("setGenesisError", {
          type: "loadingGenesisError",
          error: parseError(error),
        });
      } finally {
        commit("setGenesisDetailsLoading", {
          type: "isGenesisDetailsLoading",
          isGenesisDetailsLoading: false,
        });
      }
    },
  };

  const mutations = {
    updateValue(state, payload) {
      state.fields[payload.index].value = payload.value;
      state.fields[payload.index].isResolved = payload.isResolved;
      state.fields[payload.index].mode = payload.mode;
    },
    updateEntityValue(state, payload) {
      state.entityFields[payload.index].value = payload.value;
      state.entityFields[payload.index].isResolved = payload.isResolved;
      state.entityFields[payload.index].mode = payload.mode;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
    setFieldsLoading(state, payload) {
      state[payload.type] = payload.isFieldsLoading;
    },
    setError(state, payload) {
      state[payload.type] = payload.error;
    },
    setComponentList(state, components) {
      state.componentList = components;
    },
    setComponentListLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setComponentListError(state, error) {
      state.hasError = error;
    },
    setComponentInfoText(state, infoText) {
      state.componentInfoText = infoText;
    },
    setEntityFields(state, entityFields) {
      state.entityFields = entityFields;
    },
    setEntityData(state, entityData) {
      state.entityData = entityData;
    },
    setEntityFieldsLoading(state, payload) {
      state.isEntityFieldsLoading = payload.isEntityFieldsLoading;
    },
    setTabs(state, tabs) {
      state.tabs = tabs;
    },
    setEntityIdOnAdd(state, payload) {
      state.entityIdOnAdd = payload.entityId;
    },
    setMultipleSelectDisable(state, payload) {
      state.multipleSelectDisable = payload;
    },
    setTabName(state, payload) {
      const entityIndex = state.tabs.findIndex(
        (tab) => tab.id.toString() === payload.entityId.toString()
      );
      if (entityIndex !== -1) {
        state.tabs[entityIndex].tabName = payload.tabName;
      }
    },
    setTabsLoading(state, payload) {
      state.isTabsLoading = payload.isTabsLoading;
    },
    setAllTabs(state, payload) {
      state.allTabs = payload;
    },
    setCurrentTab(state, payload) {
      state.currentTab = payload.tabIndex;
      state.entityId = payload.entityId;
      state.componentName = payload.componentName;
    },
    setGenesisError(state, payload) {
      state[payload.type] = payload.error;
    },
    setGenesisData(state, genesisData) {
      state.genesisData = genesisData;
    },
    setCurrentGenesisFormId(state, currentGenesisFormId) {
      state.currentGenesisFormId = currentGenesisFormId;
    },
    setGenesisDetailsLoading(state, payload) {
      state.isGenesisDetailsLoading = payload.isGenesisDetailsLoading;
    },
    setCustomFieldLoading(state, payload) {
      state.isCustomFieldLoading = payload;
    },
    updateGenesisDetail(state, payload) {
      const i = state.genesisData[0].components.indexOf(payload.componentData);
      state.genesisData[0].components[i].entities = payload.newComponentId;
    },
    updateGenesisDetailOnDelete(state, payload) {
      const i = state.genesisData[0].components
        .map(function (obj) {
          return obj.componentId;
        })
        .indexOf(payload.componentId);
      state.genesisData[0].components[i].entities = payload.entities;
    },
    setGenesisType(state, payload) {
      state.genesisType = payload;
    },
    setFieldRerenderType(state, payload) {
      state.fieldRerender = payload;
    },
  };

  const state = {
    isTabsLoading: false,
    isFieldsLoading: false,
    isEntityFieldsLoading: false,
    loadingError: "",
    fields: [],
    hasError: null,
    componentList: [],
    componentInfoText: "",
    entityFields: [],
    tabs: [],
    genesisData: [],
    group: null,
    loadingGenesisError: "",
    isGenesisDetailsLoading: false,
    currentGenesisFormId: null,
    entityData: [],
    allTabs: [],
    currentTab: null,
    entityId: null,
    entityIdOnAdd: null,
    multipleSelectDisable: false,
    componentName: "",
    isCustomFieldLoading: false,
    genesisType: "",
    fieldRerender: false,
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);

import { FeatureToggleComponent as Feature } from "vue-feature-toggle";
import flagJson from "@/config/featureFlags.js";
import { SETTING_PREVIEW_MODE } from "@/config/constants.js";
import AuthService from "@/services/authService.js";
import { get } from "idb-keyval";

Feature.reloadFlags = function () {
  const currentUser = AuthService.getEmailAddress();
  const isFeatureFlagsDisabled = JSON.parse(
    sessionStorage.getItem("disableFeatureFlags")
  );

  if (!isFeatureFlagsDisabled) {
    flagJson.features.forEach((element) => {
      // Handle "visibility by email address"
      if (element.allowedUsers.length > 0) {
        this.visibility(element.name, () => {
          return element.allowedUsers.includes(currentUser);
        });
        return;
      }

      // Handle "Preview Mode" visibility (ie: off by default, not in list but user has opt-in to new stuff)
      if (element.previewMode === true) {
        get(SETTING_PREVIEW_MODE)
          .then((isPreviewModeEnabled) => {
            this.visibility(element.name, () => {
              return isPreviewModeEnabled === true;
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error fetching feature flag state:", error);
          });
      }

      // Handle generic visibility
      this.visibility(element.name, element.visible);
    });
  }
};

const FeatureFlags = {
  install(app) {
    if (JSON.parse(flagJson.logs)) {
      Feature.showLogs();
    }

    app.component("FeatureFlag", Feature);
    Feature.reloadFlags();
    app.config.globalProperties.$featureFlag = Feature;
  },
};

export default FeatureFlags;

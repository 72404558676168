<template>
  <!-- If Notifications not enabled and we can offer that...-->
  <simple-dialog v-if="canOfferNotifications" v-model="isOpen">
    <template #activator="{ props: onFirstRun }">
      <v-tooltip location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn
            id="notificationIcon"
            icon
            :aria-label="$t('common.core.notifications', 2)"
            v-bind="{ ...onTooltip, ...onFirstRun }"
          >
            <v-badge dot color="orange">
              <v-icon :icon="inboxIcon" />
            </v-badge>
          </v-btn>
        </template>
        {{ $t("common.core.notifications", 2) }}
      </v-tooltip>
    </template>

    <template #title>
      {{ $t("common.components.notifications-icon.title") }}
    </template>

    <template #actions>
      <v-btn variant="text" :disabled="isLoading" @click="showLater">
        {{ $t("common.core.later") }}
      </v-btn>
      <save-button
        :is-saving="isLoading"
        :is-saved="isActivated"
        @click="enableNotifications"
      >
        <template #label>
          {{ $t("common.components.notifications-icon.enable-button") }}
        </template>
      </save-button>
    </template>

    <v-row
      align="center"
      justify="center"
      align-content="center"
      class="responsiveHeight"
    >
      <v-col cols="12" sm="3" class="text-center d-flex justify-center">
        <v-img :src="getHeroImageUrl" max-width="200" />
      </v-col>

      <v-col cols="12" sm="9">
        <p class="text-body-2 mt-5">
          <i18n-t keypath="common.components.notifications-icon.prompt-body1">
            <template #appName>
              <app-name class="d-inline-block" />
            </template>
          </i18n-t>
        </p>

        <p class="text-body-2">
          <i18n-t keypath="">
            <template #settings>
              <code>{{ $t("common.core.settings") }}</code>
            </template>
          </i18n-t>
        </p>

        <p class="text-body-2">
          <i18n-t keypath="common.components.notifications-icon.prompt-body3">
            <template #icon>
              <v-icon size="small" :icon="inboxIcon" />
            </template>
          </i18n-t>
        </p>
      </v-col>
    </v-row>

    <error-message :error="errorMsg" class="mt-3" source="Notification" />
  </simple-dialog>

  <!-- Otherwise just show the button with badges -->
  <v-sheet v-else color="transparent">
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          id="notificationIcon"
          icon
          :to="{ name: 'notifications' }"
          class="mx-2"
          v-bind="{ props }"
        >
          <v-badge
            color="red"
            offset-x="10"
            offset-y="10"
            floating
            :content="getNotificationCount"
            :model-value="unreadNotificationCount > 0"
          >
            <v-icon :icon="inboxIcon" />
          </v-badge>
        </v-btn>
      </template>
      {{ $t("common.core.notifications") }}
    </v-tooltip>
  </v-sheet>
</template>

<script>
import WebPushMixin from "@/mixins/WebPushMixin.js";
import { parseError } from "@/services/httpResponse.js";
import { LOCALSTORAGEKEY_OFFER_NOTIFICATIONS } from "@/config/constants.js";

/**
 * 1. If SW + No Subscription for Device + Never Offered - Offer to enable + show
 * 2. If SW + Subscription - Link to Inbox
 * 3. If SW + No Subscription for Device - Offer to enable
 * 4. If SW + No Subscription for Device + "Later" - Link to Inbox
 */

export default {
  name: "NotificationsIcon",
  mixins: [WebPushMixin],
  data: () => ({
    isOpen: false,
    inboxIcon: "mdi-bell-outline",
    isLoading: false,
    isActivated: false,
    unreadNotificationCount: 0,
    notificationCountLimit: 99,
    hasOfferIgnored: false,
    errorMsg: null,
  }),
  computed: {
    getHeroImageUrl() {
      return `${
        import.meta.env.VITE_CDN_ASSETS_BASE_URL
      }/assets/person-notifications.svg`;
    },
    /**
     * If the notification count is over the limit, truncate the total to the limit
     * with a + on the end
     */
    getNotificationCount() {
      return this.unreadNotificationCount <= this.notificationCountLimit
        ? this.unreadNotificationCount
        : `${this.notificationCountLimit}+`;
    },
    /**
     * If user has SW + not a subscription for this device, offer them to turn on notifications
     * (and if they haven't ignored this prompt)
     */
    canOfferNotifications() {
      return (
        this.hasServiceWorker &&
        !this.hasSubscriptonForThisDevice &&
        !this.hasOfferIgnored
      );
    },
  },
  mounted() {
    this.hasOfferIgnored = JSON.parse(
      localStorage.getItem(LOCALSTORAGEKEY_OFFER_NOTIFICATIONS) || false
    );
  },
  methods: {
    /**
     * Handle pressing the "Deal with this later button"
     */
    showLater() {
      this.isOpen = false;
      localStorage.setItem(LOCALSTORAGEKEY_OFFER_NOTIFICATIONS, true);
      this.hasOfferIgnored = true;
    },
    /**
     * Handle pressing the "Turn them on" button
     */
    async enableNotifications() {
      try {
        this.isLoading = true;
        this.errorMsg = null;

        const subscriptions = await this.activateNotifications();
        await this.updateSubscriptions(subscriptions);

        // Show green tick on success
        this.isActivated = true;

        // We haven't ignored the offer any more, we've accepted it!
        localStorage.setItem(LOCALSTORAGEKEY_OFFER_NOTIFICATIONS, false);

        // Close dialog and reset tick after 5 seconds
        setTimeout(() => {
          this.isOpen = false;
          this.isActivated = false;
        }, 5000);
      } catch (error) {
        this.errorMsg = parseError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .responsiveHeight {
    height: 80vh;
  }
}
</style>

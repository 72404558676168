<template>
  <v-alert type="info" border="start" :variant="filled ? 'flat' : 'tonal'">
    <template v-if="title" #title>{{ title }}</template>
    <template #text><slot></slot></template>
  </v-alert>
</template>

<script>
export default {
  name: "InfoBlock",
  props: {
    title: {
      type: String,
      default: "",
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

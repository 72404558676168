import { reactive, watch, onMounted, ref } from "vue";

/**
 * Shared state to store settings values across components using a reactive Map.
 */
const sharedState = reactive(new Map());

/**
 * Opens a connection to the IndexedDB database.
 * @returns {Promise<IDBObjectStore>} Promise that resolves to the object store.
 */
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("keyval-store");
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("keyval", "readwrite");
      const objectStore = transaction.objectStore("keyval");
      resolve(objectStore);
    };
    request.onerror = () => {
      // eslint-disable-next-line no-console
      reject(new Error("Failed to open database"));
    };
  });
};

/**
 * Custom composable to manage settings with persistence.
 * @param {string} settingKey - The key to identify the setting in IndexedDB.
 * @param {any} defaultValue - The default value of the setting if not already set.
 * @returns {ref} A Vue ref associated with the specified setting.
 */
const useSettings = (settingKey = null, defaultValue = undefined) => {
  /**
   * Saves data to IndexedDB.
   * @param {any} data - The data to save.
   */
  const saveData = async (data) => {
    try {
      const objectStore = await openDB();
      const request = objectStore.put(data, settingKey);
      request.onerror = () => {
        console.error("Failed to save data to indexedDB");
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  /**
   * Retrieves data from IndexedDB and updates the reactive state.
   * @param {string} key - The key for the data in the database.
   * @param {any} defaultValue - Default value to set if the key is not found.
   */
  const retrieveData = async (key, defaultValue) => {
    try {
      const objectStore = await openDB();
      const request = objectStore.get(key);
      request.onsuccess = () => {
        if (request.result !== undefined) {
          sharedState.get(key).value = request.result;
        } else {
          // If there's no stored value, save the default value
          saveData(defaultValue);
        }
      };
      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error("Failed to retrieve data from indexedDB");
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // Initialise the setting in the shared state if not already present.
  if (!sharedState.has(settingKey)) {
    sharedState.set(settingKey, ref(defaultValue));
    retrieveData(settingKey, defaultValue);
  }

  const indexedDBRef = sharedState.get(settingKey);

  // Watch for changes and save them to IndexedDB
  onMounted(() => {
    retrieveData(settingKey, defaultValue).then(() => {
      watch(indexedDBRef, (newValue) => {
        saveData(newValue);
      });
    });
  });

  return indexedDBRef;
};

export { useSettings };

import MagnoliaAPI from "@/modules/sectors/_api/MagnoliaAPI.js";

const createStore = (app) => {
  const getters = {};

  const actions = {
    /**
     * Get a list of all availabe tours from magnolia content
     */
    async loadTours({ commit }) {
      try {
        commit("setToursListLoading", true);
        const { data } = await new MagnoliaAPI(
          app.config.globalProperties.$http
        ).getTours();
        const tours = data.results;
        commit("setToursList", tours);
      } catch (error) {
        commit("setToursListError", error);
      } finally {
        commit("setToursListLoading", false);
      }
    },
  };

  const mutations = {
    /** Magnolia DXP Tours List */
    setToursListLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setToursListError(state, error) {
      state.hasError = error;
    },
    setToursList(state, tours) {
      state.toursList = tours;
      console.log(tours);
    },
  };

  const state = {
    isLoading: false,
    hasError: null,
    toursList: [],
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
